import React from 'react';
import { render } from 'react-dom'
import {Route,Switch} from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser,logoutUser } from './actions/authActions';

import { Provider } from 'react-redux';
import store from './store';
import './App.css'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import CommonForgetPassword from './components/common/CommonForgetPassword';
import ResetPassword from './components/common/ResetPassword';

import Login from './components/doctor/Login';
import SignUp from './components/doctor/SignUp';
import DoctorHome from './components/doctor/DoctorHome';
import DoctorProfile from './components/doctor/DoctorProfile';
import AdminHome from './components/admin/AdminHome';
import DoctorsList from './components/admin/DoctorsList';
import VerifiersList from './components/admin/VerifiersList';
import AddPatient from './components/admin/AddPatient';
import AddVarifier from './components/admin/AddVarifier';
import EditVarifier from './components/admin/EditVarifier';
import AdminProfile from './components/admin/AdminProfile';
import VerifierHome from './components/verifier/VerifierHome';
import SupportHome from './components/verifier/PatientInfo';
import VerifierProfile from './components/verifier/VerifierProfile';
import VerifierChart from './components/verifier/vc_backup2';
import VerifierSelectedBeats from './components/verifier/VerifierSelectedBeats';
import Vc_backup from './components/verifier/vc_backup';
import NewChart from './components/verifier/NewChart';
import VerifierUnanalysed from './components/verifier/VerifierUnanalysed';
import DoctorUnanalysed from './components/doctor/DoctorUnanalysed';

import UserLogin from './components/user/UserLogin';
import UserSignUp from './components/user/UserSignUp';
import UserHome from './components/user/UserHome';
import UserProfile from './components/user/UserProfile';
import UserChart from './components/user/UserChart';
import ForgetPassword from './components/user/ForgetPassword';

import Main from './components/graph/Main';
import Testing from './components/graph/Testing';
import Unanalysed from './components/graph/Unanalysed';

const options = {
  title: {
    text: 'My chart'
  },
  series: [{
    data: [1, 2, 3]
  }]
}

// Check for token
if(localStorage.jwtToken) { 
    //Set auth token header auth
    setAuthToken(localStorage.jwtToken);
    //Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));
    
    //check for expired token
    const currentTime = Date.now() / 1000;
    if(decoded.exp < currentTime) {
        //Logout User
        store.dispatch(logoutUser());
        // Redirect to logiin
        window.location.href = '/';
    }
}


function App() {
  return (
    <Provider store={ store }>
      <React.Fragment>
       <Route exact path='/login' component={Login}/>
       <Route exact path='/' component={Login}/>
       <Route exact path='/signup' component={SignUp}/>
       <Route exact path='/user/forgot-password' component={CommonForgetPassword}/>

       <Route exact path='/reset-password' component={ResetPassword}/> 

       <Route exact path='/user-signup' component={UserSignUp}/>
       <Route exact path='/user-login' component={UserLogin}/>
       <Route exact path='/user-home' component={UserHome}/>
       <Route exact path='/user-profile' component={UserProfile}/>
       <Route exact path='/user-chart' component={UserChart}/>
       <Route exact path='/user-chart/:databaseid' component={UserChart}/>
       <Route exact path='/patient/forgot-password' component={ForgetPassword}/>

       <Route exact path='/doctor-home' component={DoctorHome}/>
       <Route exact path='/doctor-profile' component={DoctorProfile}/>

       <Route exact path='/main/:userid' component={Testing}/> 
       <Route exact path='/main/:userid/:databaseid' component={Testing}/>
       <Route exact path='/unanalysed/:userid' component={Unanalysed}/>
       <Route exact path='/unanalysed/:userid/:databaseid' component={Unanalysed}/>

       <Route exact path='/testing/:userid' component={Testing}/> 
       <Route exact path='/testing/:userid/:databaseid' component={Testing}/>

       <Route exact path='/admin-home' component={AdminHome}/>
       <Route exact path='/doctors-list' component={DoctorsList}/>
       <Route exact path='/verifiers-list' component={VerifiersList}/>
       <Route exact path='/add-patient' component={AddPatient}/>
       <Route exact path='/add-varifier' component={AddVarifier}/>
       <Route exact path='/edit-varifier/:id' component={EditVarifier} />
       <Route exact path='/admin-profile' component={AdminProfile} />
       <Route exact path='/support-home' component={SupportHome} />
       <Route exact path='/verifier-home' component={VerifierHome} />
       <Route exact path='/verifier-profile' component={VerifierProfile} />
       <Route exact path='/verifier-chart/:userid' component={VerifierChart} />
       <Route exact path='/verifier-chart/:userid/:databaseid' component={VerifierChart}/>
       <Route exact path='/verifier-chart/:userid/:databaseid/:start/:end/:xValue' component={VerifierChart}/>
       <Route exact path='/newchart/:userid/:databaseid' component={NewChart}/>
       <Route exact path='/verifier-unanalysed/:userid' component={VerifierUnanalysed}/>
       <Route exact path='/verifier-unanalysed/:userid/:databaseid' component={VerifierUnanalysed}/>
       <Route exact path='/doctor-unanalysed/:userid' component={DoctorUnanalysed}/>
       <Route exact path='/doctor-unanalysed/:userid/:databaseid' component={DoctorUnanalysed}/>
       
      </React.Fragment>
    </Provider>
  );
}

export default App;
